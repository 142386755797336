import React from "react"
import Collapsible from "react-collapsible"

import arrowUp from "@/public/icons/arrow-up.svg"
import arrowDown from "@/public/icons/arrow-down.svg"
import { accordionUniqueId } from "@/utils/index"
import Styles from "@/components/core/Accordion/Accordion.module.scss"
import { addAnalyticsObjForAccordion } from "@/components/core/Accordion/analytics"

const Accordion = props => {
  const {
    isOpen = false,
    analyticTitleData,
    analyticTitleCustomData,
    contentOverflow = false,
    trigger = false,
    children = "",
    title,
    handleOpen,
    handleClose,
    containerElementProps,
    isArrow = false,
  } = props

  const accordionWrapperStyle = `${Styles.accordionWrapper} accordion-container`

  const CollapsibleHeader = ({ title }) => (
    <div
      className={`collapsible__header ${
        analyticTitleData ? "gbh-data-layer" : ""
      }`}
      onClick={() => addAnalyticsObjForAccordion(analyticTitleData, analyticTitleCustomData)}
    >
      <span role="heading" aria-level="5">
        {title}
      </span>
      {!isArrow ? (
        <div className="plus" aria-hidden>
          <div className="line line-1"></div>
          <div className="line line-2"></div>
        </div>
      ) : (
        <div className="arrow" aria-hidden>
          <div className="down-arrow">
            {" "}
            <img src={arrowDown} alt={"Arrow Down"} />
          </div>
          <div className="up-arrow">
            <img src={arrowUp} alt={"Arrow Up"} />
          </div>
        </div>
      )}
    </div>
  )

  return (
    <div className={accordionWrapperStyle}>
      <div className="collapsible-accordion">
        <Collapsible
          open={isOpen}
          onTriggerOpening={handleOpen}
          onTriggerClosing={handleClose}
          containerElementProps={containerElementProps}
          overflowWhenOpen={contentOverflow ? contentOverflow : "hidden"}
          openedClassName="collapsible--open"
          tabIndex="0"
          triggerOpenedClassName="collapsible__trigger--open"
          trigger={trigger ? trigger : <CollapsibleHeader title={title} />}
          transitionTime={400}
          easing="ease-in-out"
          triggerElementProps={{
            id: accordionUniqueId("collapsible-trigger", 9999, 1000),
          }}
          contentElementId={accordionUniqueId(
            "collapsible-content",
            9999,
            1000
          )}
        >
          {children}
        </Collapsible>
      </div>
    </div>
  )
}

export default Accordion
